
import store from "@/store";
import { Album, AudioFile } from "../../store/mp3player/types";
import { defineComponent, PropType, reactive } from "vue";
import moment from 'moment';
import AudioRating from "./AudioRating.vue";

export default defineComponent({
    components: { AudioRating },
    name: 'LibraryAlbum',
    emits: ['addToPlaylist'],
    props: {
        album: {
            type: Object as PropType<Album>,
            default: null
        }
    },
    data() {
        return {
            fullAlbum: false,
            fullTime: true,
            clicks: 0,
            timer: 0,
            window: {
                width: 0,
                height: 0,
                cover: 450
            },
            styleObjectAlbum: reactive({
                width: '880px'
            }),
            styleObjectTableName: reactive({
                width: '100%'
            }),
            styleObjectLastPlayed: reactive({
                width: '100px'
            }),
            styleObjectGenre: reactive({
                width: '120px',
                display: ''
            }),
        }
    },
    created() {
        this.window = store.getters['window/windowSize']
    },
    watch: { 
        window: {
            handler(newVal) {
                if (newVal.width < 1450) {
                    const widthPlayer = 560
                    if (newVal.width > 1150) {                        
                        this.styleObjectAlbum.width = (newVal.width - widthPlayer - 20) + 'px'
                        const delta = 300 - (1350 - newVal.width)
                        if (delta > 168) {
                            if (this.styleObjectGenre.display !== '') {
                                this.styleObjectGenre.display = "";
                            }
                        } else {
                            this.styleObjectGenre.display = "none";
                        }                  
                        if (newVal.width < 1400) {
                            this.fullTime = false  
                            this.styleObjectLastPlayed.width = '70px' 
                            this.styleObjectGenre.width = "100px"                     
                        } else {
                            this.fullTime = true
                            this.styleObjectLastPlayed.width = '100px' 
                            this.styleObjectGenre.width = "120px"       
                        }
                    } else {
                        this.styleObjectAlbum.width = (1150 - widthPlayer - 20) + 'px'
                        this.fullTime = false  
                        this.styleObjectLastPlayed.width = '70px' 
                        this.styleObjectGenre.width = "100px"   
                        this.styleObjectGenre.display = "none";
                    }                    
                } else {
                    this.styleObjectAlbum.width = '880px'
                }
            },
            deep: true
        }
    },
    computed: {
        audioFilesPreview(): Array<AudioFile> {
            if (this.album) {
                if (this.fullAlbum) {
                    return this.album.audioFiles
                }
                return this.album.audioFiles.filter((el: AudioFile) => el.state !== 1 && el.state !== 2).slice(0, 10)
            }
            return []
        }
    },
    methods: {
        selectAudioTrack(audioFile: AudioFile) {
            this.clicks++;
            if (this.clicks === 1) {
                this.timer = setTimeout(() => {
                    this.clicks = 0
                    audioFile.selected = !audioFile.selected
                }, 300);
            } else if (this.clicks === 2) {
                if (!audioFile.selected) {
                    audioFile.selected = true
                }
                clearTimeout(this.timer)
                this.clicks = 0
                this.$emit('addToPlaylist')
            } else {
                clearTimeout(this.timer)
                this.clicks = 0
            }
        },
        selectAudioAlbum(album: Album) {
            let select = false
            this.clicks++;

            if (this.clicks === 1) {
                this.timer = setTimeout(() => {
                    this.clicks = 0
                    album.audioFiles.forEach(audioFile => {
                        if (audioFile.state === 1 || audioFile.state === 2) {
                            audioFile.selected = false
                        }
                        if (!audioFile.selected) {
                            select = true
                        }
                    })
                    album.audioFiles.forEach(audioFile => {
                        audioFile.selected = select
                        if (select) {
                            if (audioFile.state === 1 || audioFile.state === 2) {
                                audioFile.selected = false
                            }
                        }
                    })
                }, 300);
            } else if (this.clicks === 2) {
                album.audioFiles.forEach(audioFile => {
                    audioFile.selected = true
                    if (audioFile.state === 1 || audioFile.state === 2) {
                        audioFile.selected = false
                    }
                })
                clearTimeout(this.timer)
                this.clicks = 0
                this.$emit('addToPlaylist')
            } else {
                clearTimeout(this.timer)
                this.clicks = 0
            }
        },
        getAudioFileLastDuration(duration: number | null): string {
            if (duration) {
                return Math.floor(duration / 60) + ':' + (duration % 60 < 10 ? '0' : '') + (duration % 60)
            }
            return ''
        },
        getAlbumDuration(album: Album): string {
            let duration = 0
            album.audioFiles.forEach(audioFile => {
                duration += +audioFile.duration
            })            
            if (duration) {
                return Math.floor(duration / 60) + ':' + (duration % 60 < 10 ? '0' : '') + (duration % 60)
            }
            return ''
        },
        getAudioFileLastPlayed(dateAsString: string | null): string {
            if (dateAsString) {
                if (this.fullTime) {
                    return moment(dateAsString).format('DD.MM.YYYY HH:mm')
                } else {
                    return moment(dateAsString).format('DD.MM.YYYY')
                }                
            }
            return ''
        }
    }
})
