
import { defineComponent } from "vue";
import NavBar from '@/components/navigation/NavBar.vue'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import Mp3Player from '@/views/Mp3Player.vue'
import store from "@/store";

export default defineComponent({
  name: 'App',
  components: {
    NavBar,
    HomeView,
    LoginView,
    Mp3Player
  },
  data() {
    return {
      route: 'home',
      userServices: [],
      window: {
        width: 0,
        height: 0,
        cover: 450
      }
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      store.dispatch('window/setWindowSize', this.window)
    },
    changeRoute(routeItem: string) {    
      if (this.route != routeItem) {
        this.route = routeItem
        this.checkUser()    
      }      
    },
    checkUser() {
      if (localStorage.getItem('accessToken')) {
        if (!store.getters['user/lastUpdate']) {
          store.dispatch('user/loadUser').then(() => {
            this.userServices = store.getters['user/userServices']
          })
        } else {
          this.userServices = store.getters['user/userServices']
        }
      }  
    }
  },
  mounted() {
    this.checkUser()
  }
})
