import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '../../assets/loading.gif'


const _hoisted_1 = { ref: "audioRef" }
const _hoisted_2 = { class: "mp3playlist_controls_buttons" }
const _hoisted_3 = { class: "volume_timewrap" }
const _hoisted_4 = { class: "library-audio-list-table" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "library-audio-list-table-name" }
const _hoisted_7 = { class: "library-audio-list-table-rating" }
const _hoisted_8 = { class: "library-audio-list-table-duration" }
const _hoisted_9 = { class: "audio_controls_panel" }
const _hoisted_10 = { class: "audio_controls_buttons" }
const _hoisted_11 = { class: "audio_timewrap" }
const _hoisted_12 = { class: "audio_currenttime" }
const _hoisted_13 = { class: "audio_duration" }
const _hoisted_14 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audio_rating = _resolveComponent("audio-rating")!
  const _component_audio_rating_active = _resolveComponent("audio-rating-active")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("audio", _hoisted_1, null, 512),
    _createElementVNode("div", {
      class: "audioPlayer_block",
      style: _normalizeStyle(_ctx.styleObjectAll)
    }, [
      _createElementVNode("div", {
        class: "mp3playlist_controls",
        style: _normalizeStyle(_ctx.styleObjectAll)
      }, [
        _createElementVNode("ul", _hoisted_2, [
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "#",
              style: _normalizeStyle(_ctx.styleObjectButton),
              class: "control-icon icon-add"
            }, null, 4)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "#",
              style: _normalizeStyle(_ctx.styleObjectButton),
              class: "control-icon icon-clear",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearPlaylist()))
            }, null, 4)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "#",
              style: _normalizeStyle(_ctx.styleObjectButton),
              class: "control-icon icon-gift",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.gift()))
            }, null, 4)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "#",
              style: _normalizeStyle(_ctx.styleObjectButton),
              class: _normalizeClass(["control-icon icon-shuffle", _ctx.shuffle? 'control-icon-active' : '']),
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.shuffleHandle && _ctx.shuffleHandle(...args)))
            }, null, 6)
          ]),
          _createElementVNode("li", null, [
            _createElementVNode("a", {
              href: "#",
              style: _normalizeStyle(_ctx.styleObjectButton),
              class: _normalizeClass(["control-icon icon-loop", _ctx.loop? 'control-icon-active' : '']),
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.loopHandle && _ctx.loopHandle(...args)))
            }, null, 6)
          ])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", {
            class: "volume_progressbar",
            onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.volumeProgressbar && _ctx.volumeProgressbar(...args)))
          }, [
            _createElementVNode("div", {
              class: "bar",
              style: _normalizeStyle({ width: _ctx.volumePersent + '%' })
            }, null, 4)
          ])
        ])
      ], 4),
      _createElementVNode("div", {
        class: "mp3playlist",
        style: _normalizeStyle(_ctx.styleObjectAll)
      }, [
        _createElementVNode("div", {
          class: "mp3playlist-scrolled",
          style: _normalizeStyle(_ctx.styleObjectList)
        }, [
          _createElementVNode("table", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playList, (audioFile, index) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: audioFile.id,
                class: _normalizeClass(["library-audio-list-table-tr", _ctx.checkStateFile(audioFile.id)]),
                onClick: ($event: any) => (_ctx.selectAudioTrack(audioFile))
              }, [
                _createElementVNode("td", _hoisted_6, _toDisplayString((index+1) + ' . ' + audioFile.title), 1),
                _createElementVNode("td", _hoisted_7, [
                  _createVNode(_component_audio_rating, {
                    rating: audioFile.rating,
                    fileId: audioFile.id
                  }, null, 8, ["rating", "fileId"])
                ]),
                _createElementVNode("td", _hoisted_8, _toDisplayString(_ctx.getAudioFileLastDuration(+audioFile.duration)), 1)
              ], 10, _hoisted_5))
            }), 128))
          ])
        ], 4)
      ], 4),
      _createElementVNode("div", {
        class: "audio_controls",
        style: _normalizeStyle(_ctx.styleObjectAll)
      }, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  style: _normalizeStyle(_ctx.styleObjectButton),
                  class: "control-icon icon-audio-back",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
                }, null, 4)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  style: _normalizeStyle(_ctx.styleObjectButton),
                  class: _normalizeClass(["control-icon icon-audio-play", (_ctx.globalState === 3)? 'control-icon-active' : '']),
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.play(0)))
                }, null, 6)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  style: _normalizeStyle(_ctx.styleObjectButton),
                  class: _normalizeClass(["control-icon icon-audio-pause", (_ctx.globalState === 4)? 'control-icon-active' : '']),
                  onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.pause && _ctx.pause(...args)))
                }, null, 6)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  style: _normalizeStyle(_ctx.styleObjectButton),
                  class: _normalizeClass(["control-icon icon-audio-stop", (_ctx.globalState === 0)? 'control-icon-active' : '']),
                  onClick: _cache[8] || (_cache[8] = 
//@ts-ignore
(...args) => (_ctx.stop && _ctx.stop(...args)))
                }, null, 6)
              ]),
              _createElementVNode("li", null, [
                _createElementVNode("a", {
                  href: "#",
                  style: _normalizeStyle(_ctx.styleObjectButton),
                  class: "control-icon icon-audio-forward",
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.forward && _ctx.forward(...args)))
                }, null, 4)
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "audio_controls_rating",
            style: _normalizeStyle(_ctx.styleObjectRating)
          }, [
            _createVNode(_component_audio_rating_active, {
              rating: _ctx.playingRating,
              fileId: _ctx.playingId,
              onSetRating: _ctx.changeRating
            }, null, 8, ["rating", "fileId", "onSetRating"])
          ], 4)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("span", null, _toDisplayString(_ctx.currentTimeFormatted), 1)
          ]),
          _createElementVNode("div", {
            class: "audio_progressbar",
            onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.audioProgressbar && _ctx.audioProgressbar(...args)))
          }, [
            _createElementVNode("div", {
              class: "bar",
              style: _normalizeStyle({ width: _ctx.progressPersent + '%' })
            }, null, 4)
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("span", null, _toDisplayString(_ctx.durationFormatted), 1)
          ])
        ])
      ], 4),
      _createElementVNode("div", {
        class: "audio_image",
        style: _normalizeStyle(_ctx.styleObjectAll)
      }, [
        (_ctx.globalState === 1)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: _imports_0,
              style: _normalizeStyle(_ctx.styleObjectCover)
            }, null, 4))
          : _createCommentVNode("", true),
        (_ctx.playingAlbumId > 0 && (_ctx.globalState === 3 || _ctx.globalState === 4))
          ? (_openBlock(), _createElementBlock("img", {
              key: 1,
              style: _normalizeStyle(_ctx.styleObjectCover),
              src: 'https://backend.vdmar.ru/storage/images/'+_ctx.playingAlbumId+'/1.jpg'
            }, null, 12, _hoisted_14))
          : _createCommentVNode("", true)
      ], 4)
    ], 4)
  ], 64))
}